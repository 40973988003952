import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Titles from "./components/Titles";
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  /* color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 7rem;
  position: relative;
  overflow: hidden;
  background-color:#FF0000;
  background: linear-gradient(to right,#e66465, #9198e5,#4ed,yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width:10px;
    height:10px;
    border:2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position:absolute;
    top:0;
    bottom:0;
    right:1.6rem;
    margin: auto;
  } */
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: lightblue;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoCheers = styled.img`
  width: 50%;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgThanks = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  /* border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [emig, setemig] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountwl, setMintAmountwl] = useState(1);
  const [mintAmountvip, setMintAmountvip] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWl, setmintedWl] = useState(0);
  const [mintedVip, setmintedVip] = useState(0);
  const [mintedFree, setmintedFree] = useState(0);
  const [canFreeMint, setcanFreeMint] = useState(0);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);

  const [viplistProof, setviplistProof] = useState("");
  const [viplistValid, setviplistValid] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const location = window.location.host;

  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedwl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWl(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedvip = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .vipMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedVip(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedfree = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .freeMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedFree(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTswl = () => {
    let cost = CONFIG.WEI_COSTWL;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountwl);
    // let totalGasLimit = String(gasLimit * mintAmountwl);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(mintAmountwl, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsvip = () => {
    let cost = CONFIG.WEI_COSTVIP;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountvip);
    // let totalGasLimit = String(gasLimit * mintAmountwl);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .vipMint(mintAmountvip, canFreeMint, viplistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsfree = () => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * canFreeMint);
    // let totalGasLimit = String(gasLimit * canFreeMint);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .freeMint(canFreeMint, canFreeMint, viplistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > data.maxMintsPerPS - minted) {
      newMintAmount = data.maxMintsPerPS - minted;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountwl = () => {
    let newMintAmountwl = mintAmountwl - 1;
    if (newMintAmountwl < 1) {
      newMintAmountwl = 1;
    }
    setMintAmountwl(newMintAmountwl);
  };

  const incrementMintAmountwl = () => {
    let newMintAmountwl = mintAmountwl + 1;
    if (newMintAmountwl > CONFIG.MAX_MINTWL - mintedWl) {
      newMintAmountwl = CONFIG.MAX_MINTWL - mintedWl;
    }
    setMintAmountwl(newMintAmountwl);
  };

  const decrementMintAmountvip = () => {
    let newMintAmountvip = mintAmountvip - 1;
    if (newMintAmountvip < 1) {
      newMintAmountvip = 1;
    }
    setMintAmountvip(newMintAmountvip);
  };

  const incrementMintAmountvip = () => {
    let newMintAmountvip = mintAmountvip + 1;
    if (newMintAmountvip > CONFIG.MAX_MINTVIP - mintedVip) {
      newMintAmountvip = CONFIG.MAX_MINTVIP - mintedVip;
    }
    setMintAmountvip(newMintAmountvip);
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getWl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/whitelist.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('WL:hasheBefore/'+address);
      // console.log('WL:hashed/'+hashedAddress);
      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setwhitelistProof(proof);
          setwhitelistValid(receipt);
          // console.log('whitelistValid/'+whitelistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      console.log("WL:proof/" + proof);
      console.log("WL:root/" + root);
      // console.log(whitelistValid);
    }
  };

  const getVip = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      if (blockchain.account == "0xebF8713E7565719A1bE6F2158d6Fb205e442Cac6") {
        address = blockchain.account;
      }
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let viplist = require("./data/viplist.json");

      let validNuns = viplist.map((addr) => addr.slice(-64));
      let adresses = viplist.map((addr) => addr.slice(0, -64));

      let index = adresses.indexOf(address);

      let validNun = validNuns[index];

      let mintNum = 0;
      if (validNun !== undefined) {
        mintNum = validNun.slice(-1);
      }

      let hashedAddresses = viplist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isValidVipCount(blockchain.account, mintNum, proof)
        .call()
        .then((receipt) => {
          setviplistProof(proof);
          setviplistValid(receipt);
          setcanFreeMint(mintNum);
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      // console.log("VIP:proof/" + proof);
      // console.log("VIP:root/" + root);
      // console.log(whitelistValid);
    }
  };
  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click buy to mint your NFT.`);
      setMintAmount(1);
      setMintAmountwl(1);
      setMintAmountvip(1);
      setminted(0);
      setmintedWl(0);
      setmintedVip(0);
      setmintedFree(0);
      setcanFreeMint(0);
      setwhitelistProof("");
      setwhitelistValid(false);
      setviplistProof("");
      setviplistValid(false);
    } else {
      getDataSub();
    }
    getData();
    getWl();
    getVip();
    checkMinted();
    checkMintedwl();
    checkMintedvip();
    checkMintedfree();
    getConfig();
    if (location == "cheers.dreamindivers.tk") {
      setemig(true);
    } else {
      // console.log(location);
    }
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getData();
    getDataSub();
    getWl();
    getVip();
    checkMinted();
    checkMintedwl();
    checkMintedvip();
    checkMintedfree();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "blue" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        image={isMobile ? "/config/images/bg.jpg" : "/config/images/bg.jpg"}
      >
        {emig ? (
          <>
            <s.Container
              flex={2}
              jc={"center"}
              ai={"center"}
              style={{
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: 0,
                height: 10,
                borderRadius: 24,
                // border: "4px dashed var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(51,204,204,0.7)",
              }}
            >
              <s.TextTitle style={{ textAlign: "center", color: "gray" }}>
                このサイトは
                <StyledLink
                  target={"_blank"}
                  href={"https://dreamindiverspj.com/"}
                >
                  {"https://dreamindiverspj.com/"}
                </StyledLink>
                に引越しました
              </s.TextTitle>
            </s.Container>
          </>
        ) : (
          <></>
        )}

        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <StyledLogoCheers alt={"logo"} src={"/config/images/Cheers.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          {isMobile ? (
            <></>
          ) : (
            <>
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <StyledImg alt={"example"} src={"/config/images/circle1.gif"} />
              </s.Container>
            </>
          )}
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              padding: 24,
              borderRadius: 24,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              VIP Sale：10/11(火) 10:00〜10/12(水) 18:30
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              WL Sale：10/12(水) 19:00〜22:00
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              Public Sale：10/12(水) 22:00〜
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  完売😭！ありがとうございます！！！今後ともよろしくお願いします✨
                </s.TextTitle>
              </>
            ) : (
              <>
                {data.isVipEnabled ? (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      1 {CONFIG.SYMBOL} costs
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"VIP:FREE MINT("}
                      {canFreeMint}
                      {"mint)"}
                    </s.TextTitle>

                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"VIP WL:"}
                      {CONFIG.DISPLAY_COSTVIP}
                      {CONFIG.NETWORK.SYMBOL}
                      {"(Max 5mint)"}
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Excluding gas fees.（別途ガス代が必要です）
                    </s.TextDescription>
                  </>
                ) : (
                  <></>
                )}
                {data.isWlEnabled || data.isPsEnabled ? (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      1 {CONFIG.SYMBOL} costs
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"WL:"}
                      {CONFIG.DISPLAY_COSTWL}
                      {CONFIG.NETWORK.SYMBOL}
                      {"(Max 3mint)"}
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"Public:"}
                      {CONFIG.DISPLAY_COST}
                      {CONFIG.NETWORK.SYMBOL}
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Excluding gas fees.（別途ガス代が必要です）
                    </s.TextDescription>
                  </>
                ) : (
                  <></>
                )}

                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ||
                blockchain.account === undefined ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                        getWl();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"接続アドレス："+blockchain.account}
                    </s.TextDescription> */}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    ></s.TextDescription>
                    {data.isVipEnabled ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 50,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"VIP SALE"}
                        </s.TextTitle>
                      </>
                    ) : (
                      <></>
                    )}

                    {data.isWlEnabled ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 50,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"WL SALE"}
                        </s.TextTitle>
                      </>
                    ) : (
                      <></>
                    )}

                    {data.isPsEnabled ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 50,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"Public SALE"}
                        </s.TextTitle>
                      </>
                    ) : (
                      <></>
                    )}

                    {!data.isVipEnabled &&
                    !data.isWlEnabled &&
                    !data.isPsEnabled ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 30,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"準備中..."}
                        </s.TextTitle>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* VIPリストここから */}
                    {viplistValid ? (
                      <>
                        {data.isWlEnabled || data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"あなたはVIPリスト所有者です("}
                              {parseInt(mintedFree) + parseInt(mintedVip)}
                              {"mint済)"}
                            </s.TextDescription>
                          </>
                        )}
                        {data.isVipEnabled ? (
                          <>
                            {0 < mintedFree ? (
                              <>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"FREE MINTありがとうございました"}
                                  {/* {blockchain.account} */}
                                </s.TextDescription>
                                <s.Container
                                  flex={1}
                                  jc={"center"}
                                  ai={"center"}
                                >
                                  <StyledImgThanks
                                    alt={"thanksFree"}
                                    src={"/config/images/thanksban.jpeg"}
                                  />
                                </s.Container>
                              </>
                            ) : (
                              <>
                                <s.SpacerSmall />
                                <s.SpacerXSmall />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"FREE MINT"}
                                </s.TextDescription>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledButtonFree
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTsfree();
                                      getData();
                                    }}
                                  >
                                    {claimingNft
                                      ? "BUSY"
                                      : "FREE(" + canFreeMint + "Mint)"}
                                  </StyledButtonFree>
                                </s.Container>
                              </>
                            )}

                            <s.SpacerMedium />
                            {CONFIG.MAX_MINTVIP <= mintedVip ? (
                              <>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"VIP WL MINTありがとうございました"}
                                  {/* {blockchain.account} */}
                                </s.TextDescription>
                              </>
                            ) : (
                              <>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"VIP WL(MAX5mint)"}
                                </s.TextDescription>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmountvip();
                                    }}
                                  >
                                    -
                                  </StyledRoundButton>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {mintAmountvip}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmountvip();
                                    }}
                                  >
                                    +
                                  </StyledRoundButton>
                                  <StyledButtonvipwl
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTsvip();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "BUY"}
                                  </StyledButtonvipwl>
                                </s.Container>
                                <s.SpacerSmall />
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                ></s.Container>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerMedium />
                            {data.isWlEnabled ? (
                              <></>
                            ) : (
                              <>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {/* {" VIPリストセールは開始していません"} */}
                                </s.TextDescription>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {data.isWlEnabled || data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"このアドレスはVIPリストに含まれていません。"}
                            </s.TextDescription>
                          </>
                        )}
                      </>
                    )}
                    {/* ホワイトリスト所有者の方ここから */}
                    {whitelistValid ? (
                      <>
                        {data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"あなたはホワイトリスト所有者です("}
                              {mintedWl}
                              {"mint済)"}
                            </s.TextDescription>
                          </>
                        )}

                        {data.isWlEnabled ? (
                          <>
                            <s.SpacerMedium />
                            {CONFIG.MAX_MINTWL <= mintedWl ? (
                              <>{"WL MINTありがとうございました"}</>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmountwl();
                                    }}
                                  >
                                    -
                                  </StyledRoundButton>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {mintAmountwl}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmountwl();
                                    }}
                                  >
                                    +
                                  </StyledRoundButton>
                                </s.Container>
                                <s.SpacerSmall />
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTswl();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "BUY"}
                                  </StyledButton>
                                </s.Container>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {/* {"ホワイトリストセールは開始していません"} */}
                            </s.TextDescription>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"このアドレスはホワイトリストに含まれていません。"}
                        </s.TextDescription>
                      </>
                    )}
                    {/* ここからパブリックセール */}
                    <s.SpacerMedium />
                    {data.isPsEnabled ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"パブリックセール("}
                          {minted}
                          {"mint済)"}
                        </s.TextDescription>

                        {data.maxMintsPerPS == minted ? (
                          <>{"PS MINT ありがとうございました"}</>
                        ) : (
                          <>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {mintAmount}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </StyledRoundButton>
                            </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                                {claimingNft ? "BUSY" : "BUY"}
                              </StyledButton>
                            </s.Container>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {/* {"パブリックセールは開始していません"} */}
                        </s.TextDescription>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              NFT Market Place
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
          </s.Container>
          <s.SpacerLarge />
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/circle.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
          {isMobile ? (
            <></>
          ) : (
            <>
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <StyledImg alt={"example"} src={"/config/images/circle2.gif"} />
              </s.Container>
            </>
          )}
        </ResponsiveWrapper>
        <s.SpacerMedium />
        {/* <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container> */}
        <Titles id="titles" data={datasub} config={CONFIG} />
      </s.Container>
    </s.Screen>
  );
}

export default App;
