import { AppBar, Typography } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "../styles/globalStyles";

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 24px;
  border-radius: 24px;
  border: 4px dashed var(--secondary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextDescription = styled.p`
  color: white;
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledTitles = styled.img`
  width: 100%;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledDiverTitle = styled.img`
  width: 1.4rem;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transform: rotateY(45deg);
  animation: rotate-anime-3d 3s linear infinite;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const BlinkTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
  animation: flash 1s linear infinite;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledButton = styled.button`
  /* padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  } */
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 7rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;
function Titles({ data, config }) {
  const [pumpkin, setpumpkin] = useState(false);

  const getPumpkin = (pumpkinNum) => {
    setpumpkin(!pumpkinNum);
  };
  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            {data.loading ? (
              <>
                <TitlesTitle
                  id="Titles"
                  style={{
                    textAlign: "center",
                    perspective: "500px",
                    color: "primary.main",
                  }}
                >
                  {"Loading..."}

                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/diamond.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/platina.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/gold.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/silver.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/bronze.png"}
                  />
                </TitlesTitle>
              </>
            ) : (
              <>
                {data.balanceOf > 0 ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {"保有Divers:"}
                      {data.balanceOf}
                      {"体"}
                    </TitlesTitle>
                    {/* <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          getPumpkin(pumpkin);
                        }}
                      >
                        🎃
                      </StyledButton>
                    </TitlesTitle> */}

                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                      }}
                    >
                      {/* :nbronze: @Bronze Diver  …1枚〜2枚
                      :nsilver: @Silver Diver  3枚〜4枚
                      :ngold: @Gold Diver  5枚〜9枚
                      :nplatina: @Platinum Diver  10枚〜14枚
                      :ndiamond: @Diamond Diver  15枚〜 */}
                      {data.balanceOf >= 15 ? (
                        <>
                          <StyledDiverTitle
                            alt={"logo"}
                            src={"/config/images/gyoro/diamond.png"}
                          />
                          {"Diamond Diver"}
                          <StyledDiverTitle
                            alt={"logo"}
                            src={"/config/images/gyoro/diamond.png"}
                          />
                        </>
                      ) : (
                        <>
                          {data.balanceOf >= 10 && data.balanceOf <= 14 ? (
                            <>
                              <StyledDiverTitle
                                alt={"logo"}
                                src={"/config/images/gyoro/platina.png"}
                              />
                              {"Platinum Diver"}
                              <StyledDiverTitle
                                alt={"logo"}
                                src={"/config/images/gyoro/platina.png"}
                              />
                            </>
                          ) : (
                            <>
                              {data.balanceOf >= 5 && data.balanceOf <= 9 ? (
                                <>
                                  <StyledDiverTitle
                                    alt={"logo"}
                                    src={"/config/images/gyoro/gold.png"}
                                  />
                                  {"Gold Diver"}
                                  <StyledDiverTitle
                                    alt={"logo"}
                                    src={"/config/images/gyoro/gold.png"}
                                  />
                                </>
                              ) : (
                                <>
                                  {data.balanceOf >= 3 &&
                                  data.balanceOf <= 4 ? (
                                    <>
                                      <StyledDiverTitle
                                        alt={"logo"}
                                        src={"/config/images/gyoro/siver.png"}
                                      />
                                      {"Silver Diver"}
                                      <StyledDiverTitle
                                        alt={"logo"}
                                        src={"/config/images/gyoro/siver.png"}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {data.balanceOf < 3 ? (
                                        <>
                                          <StyledDiverTitle
                                            alt={"logo"}
                                            src={
                                              "/config/images/gyoro/bronze.png"
                                            }
                                          />
                                          {"Bronze Diver"}
                                          <StyledDiverTitle
                                            alt={"logo"}
                                            src={
                                              "/config/images/gyoro/bronze.png"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TitlesTitle>
                  </>
                ) : (
                  <>
                    {data.balanceOf === false ? (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "",
                          }}
                        >
                          {"ここに保有ダイバーが表示されます。"}
                        </TitlesTitle>
                      </>
                    ) : (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "",
                          }}
                        >
                          {"現在保有ダイバーはいません。"}
                        </TitlesTitle>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <Grid xs={12} sm={12}>
              <Box
                sx={{
                  width: 1 / 1.25,
                  height: 1 / 1,
                  mx: "auto",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              ></Box>
            </Grid>
          </Grid>
          {data.imageURI != undefined
            ? data.imageURI?.map((imageURI) => (
                <Grid
                  xs={6}
                  sm={2}
                  key={imageURI[0]}
                  style={{
                    perspective: "500px",
                  }}
                >
                  <Box
                    sx={{
                      width: 1 / 1.25,
                      height: 1 / 1,
                      mx: "auto",
                      display: "block",
                      backgroundColor: "black",
                      transformStyle: "preserve-3d",
                      animation: "fadein 3s linear forwards",
                      opacity: "0",
                      animationDelay: imageURI[0] + "s",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledTitles alt={"logo"} src={imageURI[1]} />
                    {pumpkin ? (
                      <>
                        <StyledTitlesCover
                          alt={"logo"}
                          src={"/config/images/pumpkin.png"}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <StyledLink
                      target={"_blank"}
                      href={config.MARKETPLACE_BASEURI + imageURI[3]}
                    >
                      <StyledTitlesName>{imageURI[2]}</StyledTitlesName>
                    </StyledLink>
                  </Box>
                </Grid>
              ))
            : ""}

          <Grid xs={12} sm={12}>
            <BlinkTitle
              id="Titles"
              style={{
                position: "relative",
                textAlign: "right",
                top: "100%",
              }}
            >
              {"▼"}
            </BlinkTitle>
          </Grid>

          {/* <Grid xs={12} sm={9}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "primary.main",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              <TextDescription>
                {
                  "コレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについて"
                }
                {
                  "コレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについて"
                }
                {
                  "コレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについて"
                }
              </TextDescription>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Titles;
